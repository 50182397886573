// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cloudinary from "../../../../../../libs/Cloudinary.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectStickyBarRolesDropdownScss from "../ProjectStickyBarRolesDropdown.scss";

var css = ProjectStickyBarRolesDropdownScss;

function buildLogoUrl(src) {
  return Cloudinary.Url.build("Png", {
              hd: {
                TAG: "Width",
                _0: 140
              },
              tl: /* [] */0
            }, src);
}

var Helpers = {
  buildLogoUrl: buildLogoUrl
};

function ProjectStickyBarProviderInfo(props) {
  var provider = props.provider;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("img", {
                            className: css.avatarPic,
                            src: buildLogoUrl(provider.logo)
                          }),
                      className: css.avatar
                    }),
                JsxRuntime.jsx("span", {
                      children: provider.name
                    })
              ]
            });
}

var make = ProjectStickyBarProviderInfo;

export {
  css ,
  Helpers ,
  make ,
}
/* css Not a pure module */
