// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";
import * as IconDataCenterLogo from "../../../../../../styleguide/icons/IconDataCenterLogo.res.js";
import * as ProjectStickyBarRolesDropdownScss from "../ProjectStickyBarRolesDropdown.scss";

var css = ProjectStickyBarRolesDropdownScss;

function ProjectStickyBarAdminInfo(props) {
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(IconDataCenterLogo.make, {
                            size: "XL",
                            className: css.icon
                          }),
                      className: css.avatar
                    }),
                JsxRuntime.jsx("span", {
                      children: props.title
                    })
              ]
            });
}

var make = ProjectStickyBarAdminInfo;

export {
  css ,
  make ,
}
/* css Not a pure module */
