// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "../../../../../api/Api.res.js";
import * as React from "react";
import * as $$Promise from "../../../../../bindings/Promise.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SentryLogger from "../../../../../loggers/SentryLogger.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectStickyBarScss from "./ProjectStickyBar.scss";
import * as ProjectActionButtonsForUser from "../ProjectActionButtons/ProjectActionButtonsForUser.res.js";
import * as ProjectProposalStatusDropdown from "../ProjectProposalStatusDropdown/ProjectProposalStatusDropdown.res.js";
import * as ProjectStickyBarRolesDropdown from "./ProjectStickyBarRolesDropdown.res.js";
import * as ProjectStickyBarUsersDropdown from "./ProjectStickyBarUsersDropdown.res.js";
import * as ProjectActionButtonsForProvider from "../ProjectActionButtons/ProjectActionButtonsForProvider.res.js";

var css = ProjectStickyBarScss;

function switchRoleCallback(x) {
  if (x.TAG !== "Ok") {
    return SentryLogger.error1({
                rootModule: "ProjectStickyBar",
                subModulePath: /* [] */0,
                value: "switchRoleCallback",
                fullPath: "ProjectStickyBar.switchRoleCallback"
              }, "ProjectStickyBar::SwitchRole::Error", [
                "Error",
                x._0
              ]);
  }
  window.location.reload();
}

function reducer(state, action) {
  switch (action.TAG) {
    case "UpdateProjectRole" :
        return {
                userProjectRole: action._0,
                selectedManagers: state.selectedManagers,
                selectedConcierges: state.selectedConcierges,
                usersListStatus: state.usersListStatus,
                proposal: state.proposal
              };
    case "UpdateManagers" :
        return {
                userProjectRole: state.userProjectRole,
                selectedManagers: action._0,
                selectedConcierges: state.selectedConcierges,
                usersListStatus: state.usersListStatus,
                proposal: state.proposal
              };
    case "UpdateStatus" :
        return {
                userProjectRole: state.userProjectRole,
                selectedManagers: state.selectedManagers,
                selectedConcierges: state.selectedConcierges,
                usersListStatus: action._0,
                proposal: state.proposal
              };
    case "UpdateProposal" :
        return {
                userProjectRole: state.userProjectRole,
                selectedManagers: state.selectedManagers,
                selectedConcierges: state.selectedConcierges,
                usersListStatus: state.usersListStatus,
                proposal: action._0.proposal
              };
    case "UpdateConcierges" :
        return {
                userProjectRole: state.userProjectRole,
                selectedManagers: state.selectedManagers,
                selectedConcierges: action._0,
                usersListStatus: state.usersListStatus,
                proposal: state.proposal
              };
    
  }
}

function ProjectStickyBar$ProjectStickyBarProviderUsersDropdown(props) {
  var userProjectRole = props.userProjectRole;
  if (typeof userProjectRole !== "object" || userProjectRole._0.TAG !== "Owner") {
    return null;
  } else {
    return JsxRuntime.jsx(ProjectStickyBarUsersDropdown.make, {
                title: props.tablet ? "" : "Manager",
                users: props.users,
                selectedUserIds: props.selectedUserIds,
                updateSelectedUsers: props.updateSelectedUsers,
                usersListStatus: props.usersListStatus,
                save: props.save
              });
  }
}

var ProjectStickyBarProviderUsersDropdown = {
  make: ProjectStickyBar$ProjectStickyBarProviderUsersDropdown
};

function ProjectStickyBar(props) {
  var mobile = props.mobile;
  var tablet = props.tablet;
  var desktop = props.desktop;
  var viewContext = props.viewContext;
  var projectStickyBarData = props.projectStickyBarData;
  var userRole = props.userRole;
  var projectId = projectStickyBarData.project.id;
  var initialState = React.useMemo((function () {
          var ids = projectStickyBarData.selectedManagers;
          var ids$1 = projectStickyBarData.selectedConcierges;
          return {
                  userProjectRole: projectStickyBarData.userProjectRole,
                  selectedManagers: ids !== undefined ? ids : [],
                  selectedConcierges: ids$1 !== undefined ? ids$1 : [],
                  usersListStatus: "Ready",
                  proposal: projectStickyBarData.proposalDetails
                };
        }), []);
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var saveConcierges = function () {
    dispatch({
          TAG: "UpdateStatus",
          _0: "Saving"
        });
    $$Promise.wait(Api.saveProjectConcierges(projectId, state.selectedConcierges), (function (x) {
            if (x.TAG === "Ok") {
              return dispatch({
                          TAG: "UpdateStatus",
                          _0: "Ready"
                        });
            } else {
              return SentryLogger.error1({
                          rootModule: "ProjectStickyBar",
                          subModulePath: /* [] */0,
                          value: "make",
                          fullPath: "ProjectStickyBar.make"
                        }, "ProjectStickyBar::SaveConcierges::Error", [
                          "Error",
                          x._0
                        ]);
            }
          }));
  };
  var saveManagers = function () {
    dispatch({
          TAG: "UpdateStatus",
          _0: "Saving"
        });
    $$Promise.wait(Api.saveProjectManagers(projectId, state.selectedManagers), (function (x) {
            if (x.TAG === "Ok") {
              return dispatch({
                          TAG: "UpdateStatus",
                          _0: "Ready"
                        });
            } else {
              return SentryLogger.error1({
                          rootModule: "ProjectStickyBar",
                          subModulePath: /* [] */0,
                          value: "make",
                          fullPath: "ProjectStickyBar.make"
                        }, "ProjectStickyBar::SaveManagers::Error", [
                          "Error",
                          x._0
                        ]);
            }
          }));
  };
  var updateProposalStatus = function (projectId, proposal, message, param) {
    $$Promise.wait(Api.updateProjectProposalStatus(projectId, proposal, message, undefined), (function (x) {
            if (x.TAG === "Ok") {
              return dispatch({
                          TAG: "UpdateProposal",
                          _0: x._0
                        });
            } else {
              return SentryLogger.error1({
                          rootModule: "ProjectStickyBar",
                          subModulePath: /* [] */0,
                          value: "make",
                          fullPath: "ProjectStickyBar.make"
                        }, "ProjectStickyBar::UpdateProposalStatus::Error", [
                          "Error",
                          x._0
                        ]);
            }
          }));
  };
  var tmp;
  var exit = 0;
  switch (viewContext) {
    case "Personal" :
    case "Admin" :
        exit = 1;
        break;
    case "Visitor" :
        tmp = null;
        break;
    
  }
  if (exit === 1) {
    var exit$1 = 0;
    switch (userRole) {
      case "Admin" :
          tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
                children: [
                  desktop ? JsxRuntime.jsx(ProjectStickyBarUsersDropdown.make, {
                          title: "Concierge",
                          users: projectStickyBarData.concierges,
                          selectedUserIds: state.selectedConcierges,
                          updateSelectedUsers: (function (selectedUserIds) {
                              dispatch({
                                    TAG: "UpdateConcierges",
                                    _0: selectedUserIds
                                  });
                            }),
                          usersListStatus: state.usersListStatus,
                          save: saveConcierges
                        }) : null,
                  JsxRuntime.jsx(ProjectActionButtonsForUser.make, {
                        status: projectStickyBarData.project.status,
                        userRole: userRole,
                        projectStickyBarData: projectStickyBarData,
                        saveManagers: saveManagers,
                        updateProposalStatus: updateProposalStatus,
                        tablet: tablet,
                        mobile: mobile,
                        viewContext: viewContext
                      })
                ]
              });
          break;
      case "Provider" :
          if (props.canSendProposals) {
            var match$1 = projectStickyBarData.project.status;
            var tmp$1;
            tmp$1 = match$1 === "Active" ? JsxRuntime.jsx(ProjectActionButtonsForProvider.make, {
                    projectId: projectId,
                    userRole: userRole
                  }) : null;
            tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
                  children: [
                    desktop ? JsxRuntime.jsx(ProjectStickyBar$ProjectStickyBarProviderUsersDropdown, {
                            userProjectRole: state.userProjectRole,
                            users: projectStickyBarData.providerMembers,
                            selectedUserIds: state.selectedManagers,
                            updateSelectedUsers: (function (selectedUserIds) {
                                dispatch({
                                      TAG: "UpdateManagers",
                                      _0: selectedUserIds
                                    });
                              }),
                            usersListStatus: state.usersListStatus,
                            save: saveManagers,
                            tablet: tablet
                          }) : null,
                    tmp$1
                  ]
                });
          } else {
            var proposal = state.proposal;
            tmp = proposal !== undefined ? (
                mobile ? JsxRuntime.jsx(ProjectActionButtonsForUser.make, {
                        status: projectStickyBarData.project.status,
                        userRole: userRole,
                        projectStickyBarData: projectStickyBarData,
                        saveManagers: saveManagers,
                        updateProposalStatus: updateProposalStatus,
                        tablet: tablet,
                        mobile: mobile,
                        viewContext: viewContext
                      }) : JsxRuntime.jsxs(JsxRuntime.Fragment, {
                        children: [
                          JsxRuntime.jsx(ProjectStickyBar$ProjectStickyBarProviderUsersDropdown, {
                                userProjectRole: state.userProjectRole,
                                users: projectStickyBarData.providerMembers,
                                selectedUserIds: state.selectedManagers,
                                updateSelectedUsers: (function (selectedUserIds) {
                                    dispatch({
                                          TAG: "UpdateManagers",
                                          _0: selectedUserIds
                                        });
                                  }),
                                usersListStatus: state.usersListStatus,
                                save: saveManagers,
                                tablet: tablet
                              }),
                          JsxRuntime.jsx(ProjectProposalStatusDropdown.make, {
                                proposal: proposal,
                                projectId: projectId,
                                userViewContext: "Admin",
                                iconSize: "XS",
                                updateProposalStatus: updateProposalStatus,
                                setBidModalVisibility: (function (prim) {
                                    
                                  }),
                                className: css.providerDropdown,
                                position: {
                                  TAG: "Above",
                                  _0: "LeftEdge"
                                }
                              })
                        ]
                      })
              ) : null;
          }
          break;
      case "User" :
      case "Concierge" :
          exit$1 = 2;
          break;
      case "Visitor" :
      case "Agent" :
          tmp = null;
          break;
      
    }
    if (exit$1 === 2) {
      tmp = JsxRuntime.jsx(ProjectActionButtonsForUser.make, {
            status: projectStickyBarData.project.status,
            userRole: userRole,
            projectStickyBarData: projectStickyBarData,
            saveManagers: saveManagers,
            updateProposalStatus: updateProposalStatus,
            tablet: tablet,
            mobile: mobile,
            viewContext: viewContext
          });
    }
    
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(ProjectStickyBarRolesDropdown.make, {
                            owner: projectStickyBarData.project.owner,
                            providersAsOwner: projectStickyBarData.providersAsOwner,
                            providersAsManager: projectStickyBarData.providersAsManager,
                            isAdmin: projectStickyBarData.isAdmin,
                            isConcierge: projectStickyBarData.isConcierge,
                            isOwner: projectStickyBarData.isOwner,
                            isProviderOwner: projectStickyBarData.isProviderOwner,
                            isProviderManager: projectStickyBarData.isProviderManager,
                            userProjectRole: state.userProjectRole,
                            updateProjectRole: (function (role) {
                                dispatch({
                                      TAG: "UpdateProjectRole",
                                      _0: role
                                    });
                                if (typeof role === "object") {
                                  return $$Promise.wait(Api.switchProjectRole(role, projectStickyBarData.isAdmin, projectId, Caml_option.some(role._0._0), undefined), switchRoleCallback);
                                }
                                role === "Admin";
                                $$Promise.wait(Api.switchProjectRole(role, projectStickyBarData.isAdmin, projectId, undefined, undefined), switchRoleCallback);
                              })
                          }),
                      className: css.rolesContainer
                    }),
                JsxRuntime.jsx("div", {
                      children: tmp,
                      className: css.rightCol
                    })
              ],
              className: css.container,
              id: "project-sticky-bar"
            });
}

var make = ProjectStickyBar;

export {
  css ,
  switchRoleCallback ,
  reducer ,
  ProjectStickyBarProviderUsersDropdown ,
  make ,
}
/* css Not a pure module */
