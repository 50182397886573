// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Picture from "../../../../../../styleguide/forms/Picture/Picture.res.js";
import * as Cloudinary from "../../../../../../libs/Cloudinary.res.js";
import * as IconUserAvatar from "../../../../../../styleguide/icons/IconUserAvatar.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectStickyBarRolesDropdownScss from "../ProjectStickyBarRolesDropdown.scss";

var css = ProjectStickyBarRolesDropdownScss;

function ProjectStickyBarOwnerInfo(props) {
  var owner = props.owner;
  var image = owner.profilePic;
  var tmp;
  var exit = 0;
  if (image !== undefined && image !== "") {
    tmp = JsxRuntime.jsx(Picture.make, {
          src: Cloudinary.imageUrl(image),
          large: [
            60,
            60
          ],
          crop: "Fill",
          imageClassName: css.avatarPic,
          fallbackSrc: Cloudinary.imageUrl("static/profile-avatar")
        });
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = JsxRuntime.jsx(IconUserAvatar.make, {
          size: "XL",
          className: css.icon
        });
  }
  var title = owner.jobTitle;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("div", {
                      children: tmp,
                      className: css.avatar
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("p", {
                              children: owner.fullName,
                              className: css.ownerName
                            }),
                        title !== undefined ? JsxRuntime.jsx("p", {
                                children: title,
                                className: css.ownerJobTitle
                              }) : null
                      ]
                    })
              ]
            });
}

var make = ProjectStickyBarOwnerInfo;

export {
  css ,
  make ,
}
/* css Not a pure module */
