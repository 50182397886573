// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../../../libs/ID.res.js";
import * as React from "react";
import * as Dropdown from "../../../../../styleguide/forms/Dropdown/Dropdown.res.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as SearchField from "../../../../../styleguide/forms/SearchField/SearchField.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectStickyBarAdminInfo from "./info/ProjectStickyBarAdminInfo.res.js";
import * as ProjectStickyBarOwnerInfo from "./info/ProjectStickyBarOwnerInfo.res.js";
import * as ProjectStickyBarProviderInfo from "./info/ProjectStickyBarProviderInfo.res.js";
import * as ProjectStickyBarRolesDropdownScss from "./ProjectStickyBarRolesDropdown.scss";

var css = ProjectStickyBarRolesDropdownScss;

function ProjectStickyBarRolesDropdown$ProvidersList(props) {
  var onClick = props.onClick;
  var query = props.query;
  var providers = props.providers;
  if (query === "") {
    return Belt_Array.map(providers, (function (provider) {
                  return JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(ProjectStickyBarProviderInfo.make, {
                                    provider: provider
                                  }),
                              className: css.item,
                              onClick: (function (param) {
                                  onClick(provider);
                                })
                            }, ID.toString(provider.id));
                }));
  } else {
    return Belt_Array.map((function (__x) {
                    return Js_array.filter((function (item) {
                                  var __x = item.name.toLowerCase();
                                  return Js_string.includes(query.toLowerCase(), __x);
                                }), __x);
                  })(providers), (function (provider) {
                  return JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(ProjectStickyBarProviderInfo.make, {
                                    provider: provider
                                  }),
                              className: css.item,
                              onClick: (function (param) {
                                  onClick(provider);
                                })
                            }, ID.toString(provider.id));
                }));
  }
}

var ProvidersList = {
  make: ProjectStickyBarRolesDropdown$ProvidersList
};

function ProjectStickyBarRolesDropdown(props) {
  var updateProjectRole = props.updateProjectRole;
  var userProjectRole = props.userProjectRole;
  var isProviderManager = props.isProviderManager;
  var isProviderOwner = props.isProviderOwner;
  var isOwner = props.isOwner;
  var isConcierge = props.isConcierge;
  var isAdmin = props.isAdmin;
  var providersAsManager = props.providersAsManager;
  var providersAsOwner = props.providersAsOwner;
  var owner = props.owner;
  var match = React.useState(function () {
        return "";
      });
  var setQuery = match[1];
  var query = match[0];
  var isBodyExists = isAdmin || (
    isConcierge ? (
        isOwner || isProviderOwner || isProviderManager ? true : false
      ) : (
        isOwner ? (
            isProviderOwner || isProviderManager ? true : false
          ) : (
            isProviderOwner ? (
                isProviderManager ? true : false
              ) : (
                isProviderManager ? false : true
              )
          )
      )
  );
  var tmp;
  if (typeof userProjectRole !== "object") {
    tmp = userProjectRole === "Admin" ? JsxRuntime.jsx(ProjectStickyBarAdminInfo.make, {
            title: "View as Admin"
          }) : JsxRuntime.jsx(ProjectStickyBarOwnerInfo.make, {
            owner: owner
          });
  } else {
    var id = userProjectRole._0;
    if (id.TAG === "Owner") {
      var id$1 = id._0;
      var provider = providersAsOwner.find(function (item) {
            return ID.toString(id$1) === ID.toString(item.id);
          });
      tmp = provider !== undefined ? JsxRuntime.jsx(ProjectStickyBarProviderInfo.make, {
              provider: provider
            }) : null;
    } else {
      var id$2 = id._0;
      var provider$1 = providersAsManager.find(function (item) {
            return ID.toString(id$2) === ID.toString(item.id);
          });
      tmp = provider$1 !== undefined ? JsxRuntime.jsx(ProjectStickyBarProviderInfo.make, {
              provider: provider$1
            }) : null;
    }
  }
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs(Dropdown.make, {
                    className: css.rolesList,
                    children: [
                      JsxRuntime.jsx(Dropdown.Trigger.make, {
                            className: css.currentRoleWrapper,
                            iconSize: "XS",
                            iconColor: "LighterGray",
                            isBodyExists: isBodyExists,
                            children: JsxRuntime.jsx("div", {
                                  children: tmp,
                                  className: css.currentRole,
                                  id: "project-sticky-bar-current-role"
                                })
                          }),
                      isBodyExists ? JsxRuntime.jsxs(Dropdown.Body.make, {
                              position: {
                                TAG: "Above",
                                _0: "LeftEdge"
                              },
                              className: css.dropdownBody,
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: "Select provider",
                                      className: css.dropdownTitle
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: JsxRuntime.jsx(SearchField.make, {
                                                    id: "project-sticky-bar-find-role",
                                                    value: query,
                                                    placeholder: "Search provider",
                                                    inputClassName: css.searchFieldInput,
                                                    iconClassName: css.searchIcon,
                                                    onChange: (function ($$event) {
                                                        setQuery($$event.target.value);
                                                      })
                                                  }),
                                              className: css.search
                                            }),
                                        isAdmin || isConcierge ? JsxRuntime.jsx("div", {
                                                children: JsxRuntime.jsx(ProjectStickyBarAdminInfo.make, {
                                                      title: "Admin"
                                                    }),
                                                className: css.item,
                                                onClick: (function (param) {
                                                    updateProjectRole("Admin");
                                                  })
                                              }, "admin") : null,
                                        isAdmin || isOwner ? JsxRuntime.jsx("div", {
                                                children: JsxRuntime.jsx(ProjectStickyBarOwnerInfo.make, {
                                                      owner: owner
                                                    }),
                                                className: css.item,
                                                onClick: (function (param) {
                                                    updateProjectRole("Owner");
                                                  })
                                              }, "owner") : null,
                                        isAdmin || isProviderOwner ? JsxRuntime.jsx(ProjectStickyBarRolesDropdown$ProvidersList, {
                                                providers: providersAsOwner,
                                                query: query,
                                                onClick: (function (provider) {
                                                    updateProjectRole({
                                                          TAG: "Provider",
                                                          _0: {
                                                            TAG: "Owner",
                                                            _0: provider.id
                                                          }
                                                        });
                                                  })
                                              }) : null,
                                        isAdmin || isProviderManager ? JsxRuntime.jsx(ProjectStickyBarRolesDropdown$ProvidersList, {
                                                providers: providersAsManager,
                                                query: query,
                                                onClick: (function (provider) {
                                                    updateProjectRole({
                                                          TAG: "Provider",
                                                          _0: {
                                                            TAG: "Member",
                                                            _0: provider.id
                                                          }
                                                        });
                                                  })
                                              }) : null
                                      ],
                                      id: "project-sticky-bar-roles-list"
                                    })
                              ]
                            }) : null
                    ]
                  }),
              className: css.container
            });
}

var make = ProjectStickyBarRolesDropdown;

export {
  css ,
  ProvidersList ,
  make ,
}
/* css Not a pure module */
