// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as React from "react";
import * as Button from "../../../../../styleguide/components/Button/Button.res.js";
import * as NoBidModal from "../../../../../styleguide/modals/NoBidModal/NoBidModal.res.js";
import * as Routes_Project from "../../../../../routes/common/Routes_Project.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectActionButtonsScss from "./ProjectActionButtons.scss";

var css = ProjectActionButtonsScss;

function reducer(state, action) {
  return {
          isNoBidModalShown: !state.isNoBidModalShown
        };
}

function ProjectActionButtonsForProvider(props) {
  var projectId = props.projectId;
  var match = React.useReducer(reducer, {
        isNoBidModalShown: false
      });
  var dispatch = match[1];
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(Button.AsLink.make, {
                      id: "sendProposalButton",
                      href: Routes_Project.Proposal.$$new(projectId),
                      size: "XS",
                      color: "Teal",
                      buttonClassName: css.headerButton,
                      children: "Send Proposal"
                    }),
                JsxRuntime.jsx(Button.make, {
                      id: "noBidButton",
                      size: "XS",
                      color: "Gray",
                      buttonClassName: Cx.cx([
                            css.headerButton,
                            css.noMarginRight
                          ]),
                      onClick: (function (param) {
                          dispatch("ToogleNoBidModal");
                        }),
                      children: "No Bid"
                    }),
                match[0].isNoBidModalShown ? JsxRuntime.jsx(NoBidModal.make, {
                        title: "No Bid",
                        close: (function () {
                            dispatch("ToogleNoBidModal");
                          }),
                        projectId: projectId,
                        userRole: props.userRole
                      }) : null
              ],
              className: css.actionButtonsContainer
            });
}

var make = ProjectActionButtonsForProvider;

export {
  css ,
  reducer ,
  make ,
}
/* css Not a pure module */
