// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Url from "../../../../../utils/Url.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Routes_Project from "../../../../../routes/common/Routes_Project.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectNavbarScss from "./ProjectNavbar.scss";

var css = ProjectNavbarScss;

function ProjectNavbar(props) {
  var totalProposals = props.totalProposals;
  var projectId = props.projectId;
  var userRole = props.userRole;
  var activeTab = props.activeTab;
  var tmp;
  switch (props.viewContext) {
    case "Personal" :
        tmp = totalProposals !== undefined && totalProposals !== 0 ? (
            totalProposals !== 1 ? String(totalProposals) + " Proposals" : "1 Proposal"
          ) : "Proposals";
        break;
    case "Admin" :
        switch (userRole) {
          case "Provider" :
              tmp = totalProposals !== undefined && totalProposals !== 0 ? "Your Proposal" : "Send Proposal";
              break;
          case "Visitor" :
              tmp = "";
              break;
          default:
            tmp = totalProposals !== undefined && totalProposals !== 0 ? (
                totalProposals !== 1 ? String(totalProposals) + " Proposals" : "1 Proposal"
              ) : "Proposals";
        }
        break;
    case "Visitor" :
        tmp = "";
        break;
    
  }
  var tmp$1;
  switch (userRole) {
    case "Provider" :
    case "User" :
    case "Visitor" :
        tmp$1 = "";
        break;
    default:
      tmp$1 = "Manage Providers";
  }
  var tabs = [
    {
      id: "Details",
      label: "Project Details"
    },
    {
      id: "Proposals",
      label: tmp
    },
    {
      id: "Providers",
      label: tmp$1
    }
  ];
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx("div", {
                    children: Belt_Array.mapWithIndex(tabs, (function (index, tab) {
                            var match = tab.id;
                            return JsxRuntime.jsx("span", {
                                        children: tab.label,
                                        className: Cx.cx([
                                              css.tabItem,
                                              activeTab === tab.id ? css.activeTab : ""
                                            ]),
                                        id: match === "Proposals" ? "proposalsTab" : (
                                            match === "Details" ? "detailsTab" : "providersTab"
                                          ),
                                        onClick: (function (param) {
                                            var match = tab.id;
                                            if (match === "Proposals") {
                                              return Url.visit(Routes_Project.Proposal.index(projectId));
                                            } else if (match === "Details") {
                                              return Url.visit(Routes_Project.detail(projectId));
                                            } else {
                                              return Url.visit(Routes_Project.Provider.index(projectId));
                                            }
                                          })
                                      }, tab.label + String(index));
                          })),
                    className: css.barContainer
                  }),
              className: css.wrapper
            });
}

var make = ProjectNavbar;

export {
  css ,
  make ,
}
/* css Not a pure module */
