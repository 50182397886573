// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../../../libs/ID.res.js";
import * as Tag from "../../../../../styleguide/components/Tag/Tag.res.js";
import * as Role from "../../../../../models/Role.res.js";
import * as React from "react";
import * as Button from "../../../../../styleguide/components/Button/Button.res.js";
import * as $$String from "rescript/lib/es6/string.js";
import * as Picture from "../../../../../styleguide/forms/Picture/Picture.res.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Checkbox from "../../../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as Dropdown from "../../../../../styleguide/forms/Dropdown/Dropdown.res.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Cloudinary from "../../../../../libs/Cloudinary.res.js";
import * as SearchField from "../../../../../styleguide/forms/SearchField/SearchField.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectStickyBarUsersDropdownScss from "./ProjectStickyBarUsersDropdown.scss";

var css = ProjectStickyBarUsersDropdownScss;

function ProjectStickyBarUsersDropdown$UserRow(props) {
  var updateSelectedUsers = props.updateSelectedUsers;
  var selectedUserIds = props.selectedUserIds;
  var user = props.user;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(Checkbox.make, {
                      id: "project-sticky-bar-user" + ID.toString(user.id),
                      size: "SM",
                      checked: (function (__x) {
                            return Js_array.includes(user.id, __x);
                          })(selectedUserIds),
                      onChange: (function ($$event) {
                          var checked = $$event.target.checked;
                          if (checked) {
                            return updateSelectedUsers(Belt_Array.concat(selectedUserIds, [user.id]));
                          } else {
                            return updateSelectedUsers(Belt_Array.keep(selectedUserIds, (function (value) {
                                              return Caml_obj.notequal(value, user.id);
                                            })));
                          }
                        })
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(Picture.make, {
                            src: Cloudinary.imageUrl(user.profilePic),
                            large: [
                              40,
                              40
                            ],
                            crop: "Fill",
                            imageClassName: css.avatarPic,
                            fallbackSrc: Cloudinary.imageUrl("static/profile-avatar")
                          }),
                      className: css.avatar
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("span", {
                                      children: user.firstName + (" " + user.lastName),
                                      className: css.userName
                                    }),
                                JsxRuntime.jsx(Tag.make, {
                                      text: Role.toCapital(user.role),
                                      className: css.roleTag
                                    })
                              ],
                              className: css.nameAndBadgeWrapper
                            }),
                        JsxRuntime.jsx("p", {
                              children: user.jobTitle,
                              className: css.userJobTitle
                            })
                      ]
                    })
              ],
              className: css.item
            });
}

var UserRow = {
  make: ProjectStickyBarUsersDropdown$UserRow
};

function ProjectStickyBarUsersDropdown(props) {
  var save = props.save;
  var updateSelectedUsers = props.updateSelectedUsers;
  var selectedUserIds = props.selectedUserIds;
  var users = props.users;
  var title = props.title;
  var match = React.useState(function () {
        return "";
      });
  if (users.length === 0) {
    return null;
  }
  var setQuery = match[1];
  var query = match[0];
  var n = selectedUserIds.length;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: title,
                      className: css.label
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs(Dropdown.make, {
                              className: css.usersList,
                              children: [
                                JsxRuntime.jsx(Dropdown.Trigger.make, {
                                      className: css.pickedUsers,
                                      iconSize: "XS",
                                      iconColor: "LighterGray",
                                      children: n !== 0 ? (
                                          n !== 1 ? String(n) + (" " + ($$String.lowercase_ascii(title) + "s")) : "1 " + $$String.lowercase_ascii(title)
                                        ) : "Select"
                                    }),
                                JsxRuntime.jsxs(Dropdown.Body.make, {
                                      position: {
                                        TAG: "Above",
                                        _0: "LeftEdge"
                                      },
                                      className: css.dropdownBody,
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: "Select " + title,
                                              className: css.dropdownTitle
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: JsxRuntime.jsx(SearchField.make, {
                                                    id: "project-sticky-bar-find-user",
                                                    value: query,
                                                    placeholder: "Search " + ($$String.lowercase_ascii(title) + " name or title"),
                                                    inputClassName: css.searchFieldInput,
                                                    iconClassName: css.searchIcon,
                                                    onChange: (function ($$event) {
                                                        setQuery($$event.target.value);
                                                      })
                                                  }),
                                              className: css.search
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: selectedUserIds.length === users.length ? JsxRuntime.jsx("span", {
                                                      children: "Deselect all",
                                                      className: css.selectButton,
                                                      onClick: (function (param) {
                                                          updateSelectedUsers([]);
                                                        })
                                                    }) : JsxRuntime.jsx("span", {
                                                      children: "Select all",
                                                      className: css.selectButton,
                                                      onClick: (function (param) {
                                                          updateSelectedUsers(Belt_Array.map(users, (function (user) {
                                                                      return user.id;
                                                                    })));
                                                        })
                                                    }),
                                              className: css.selectAll
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: query === "" ? Belt_Array.map(users, (function (user) {
                                                        return JsxRuntime.jsx(ProjectStickyBarUsersDropdown$UserRow, {
                                                                    user: user,
                                                                    selectedUserIds: selectedUserIds,
                                                                    updateSelectedUsers: updateSelectedUsers
                                                                  }, ID.toString(user.id));
                                                      })) : Belt_Array.map(users.filter(function (item) {
                                                          if ((function (__x) {
                                                                  return Js_string.includes(query.toLowerCase(), __x);
                                                                })(item.firstName.toLowerCase())) {
                                                            return true;
                                                          } else {
                                                            var __x = item.lastName.toLowerCase();
                                                            return Js_string.includes(query.toLowerCase(), __x);
                                                          }
                                                        }), (function (user) {
                                                        return JsxRuntime.jsx(ProjectStickyBarUsersDropdown$UserRow, {
                                                                    user: user,
                                                                    selectedUserIds: selectedUserIds,
                                                                    updateSelectedUsers: updateSelectedUsers
                                                                  }, ID.toString(user.id));
                                                      })),
                                              className: css.list
                                            })
                                      ]
                                    })
                              ]
                            }),
                        JsxRuntime.jsx(Button.make, {
                              size: "MD",
                              color: "Teal",
                              busy: props.usersListStatus !== "Ready",
                              className: css.save,
                              onClick: (function (param) {
                                  save();
                                }),
                              children: "Save"
                            })
                      ],
                      className: css.actionContainer
                    })
              ],
              className: css.container,
              id: "project-sticky-bar-users-dropdown"
            });
}

var make = ProjectStickyBarUsersDropdown;

export {
  css ,
  UserRow ,
  make ,
}
/* css Not a pure module */
