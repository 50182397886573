// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconDataCenterLogo(props) {
  var __title = props.title;
  var title = __title !== undefined ? __title : "Datacenter logo";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              viewBoxSize: "30",
              className: props.className,
              children: JsxRuntime.jsxs("g", {
                    children: [
                      JsxRuntime.jsx("path", {
                            d: "M30 15L15 30 12 27 24.3 15 12.1 2.9 15 0l12 12zM3.2 15.1l6.7 6.7-1.6 1.6L0 15.1l1.6-1.5 6.7-6.7 1.6 1.5z",
                            fill: "#1E2022",
                            fillRule: "nonzero"
                          }),
                      JsxRuntime.jsx("path", {
                            d: "M12.5 11.5l3.6 3.6-3.6 3.7L9 15z",
                            fill: "#7ACCC8"
                          })
                    ],
                    fill: "none",
                    fillRule: "evenodd"
                  })
            });
}

var make = IconDataCenterLogo;

export {
  make ,
}
/* Icon Not a pure module */
