// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Api from "../../../../../api/Api.res.js";
import * as Url from "../../../../../utils/Url.res.js";
import * as React from "react";
import * as Button from "../../../../../styleguide/components/Button/Button.res.js";
import * as Toggle from "../../../../../styleguide/forms/Toggle/Toggle.res.js";
import * as Popover from "../../../../../styleguide/components/Popover/Popover.res.js";
import * as Project from "../../../../../models/Project.res.js";
import * as $$Promise from "../../../../../bindings/Promise.res.js";
import * as IconMenu from "../../../../../styleguide/icons/IconMenu.res.js";
import * as SentryLogger from "../../../../../loggers/SentryLogger.res.js";
import * as ProjectService from "../../../../../services/ProjectService.res.js";
import * as Routes_Project from "../../../../../routes/common/Routes_Project.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectActionButtonsScss from "./ProjectActionButtons.scss";
import * as ProjectProposalStatusDropdown from "../ProjectProposalStatusDropdown/ProjectProposalStatusDropdown.res.js";
import * as ProjectStickyBarUsersDropdown from "../ProjectStickyBar/ProjectStickyBarUsersDropdown.res.js";

var css = ProjectActionButtonsScss;

function statusClass(status) {
  switch (status) {
    case "Finalized" :
        return css.finalizedText;
    case "Review" :
        return css.reviewText;
    default:
      return "";
  }
}

function statusContainerClass(status) {
  switch (status) {
    case "Finalized" :
        return css.finalizedContainer;
    case "Review" :
        return css.reviewContainer;
    default:
      return "";
  }
}

var Helpers = {
  statusClass: statusClass,
  statusContainerClass: statusContainerClass
};

function reducer(state, action) {
  switch (action.TAG) {
    case "UpdateProjectRole" :
        return {
                userProjectRole: action._0,
                selectedManagers: state.selectedManagers,
                selectedConcierges: state.selectedConcierges,
                usersListStatus: state.usersListStatus,
                proposal: state.proposal
              };
    case "UpdateManagers" :
        return {
                userProjectRole: state.userProjectRole,
                selectedManagers: action._0,
                selectedConcierges: state.selectedConcierges,
                usersListStatus: state.usersListStatus,
                proposal: state.proposal
              };
    case "UpdateStatus" :
        return {
                userProjectRole: state.userProjectRole,
                selectedManagers: state.selectedManagers,
                selectedConcierges: state.selectedConcierges,
                usersListStatus: action._0,
                proposal: state.proposal
              };
    case "UpdateProposal" :
        return {
                userProjectRole: state.userProjectRole,
                selectedManagers: state.selectedManagers,
                selectedConcierges: state.selectedConcierges,
                usersListStatus: state.usersListStatus,
                proposal: action._0.proposal
              };
    case "UpdateConcierges" :
        return {
                userProjectRole: state.userProjectRole,
                selectedManagers: state.selectedManagers,
                selectedConcierges: action._0,
                usersListStatus: state.usersListStatus,
                proposal: state.proposal
              };
    
  }
}

function ProjectActionButtonsForUser$ProjectStickyBarProviderUsersDropdown(props) {
  var userProjectRole = props.userProjectRole;
  if (typeof userProjectRole !== "object" || userProjectRole._0.TAG !== "Owner") {
    return null;
  } else {
    return JsxRuntime.jsx(ProjectStickyBarUsersDropdown.make, {
                title: "Manager",
                users: props.users,
                selectedUserIds: props.selectedUserIds,
                updateSelectedUsers: props.updateSelectedUsers,
                usersListStatus: props.usersListStatus,
                save: props.save
              });
  }
}

var ProjectStickyBarProviderUsersDropdown = {
  make: ProjectActionButtonsForUser$ProjectStickyBarProviderUsersDropdown
};

function ProjectActionButtonsForUser(props) {
  var mobile = props.mobile;
  var projectStickyBarData = props.projectStickyBarData;
  var userRole = props.userRole;
  var status = props.status;
  var projectId = projectStickyBarData.project.id;
  var category = Project.Category.fromString(projectStickyBarData.project.category, projectStickyBarData.project.subcategory);
  var initialState = React.useMemo((function () {
          var ids = projectStickyBarData.selectedManagers;
          var ids$1 = projectStickyBarData.selectedConcierges;
          return {
                  userProjectRole: projectStickyBarData.userProjectRole,
                  selectedManagers: ids !== undefined ? ids : [],
                  selectedConcierges: ids$1 !== undefined ? ids$1 : [],
                  usersListStatus: "Ready",
                  proposal: projectStickyBarData.proposalDetails
                };
        }), []);
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var saveConcierges = function () {
    dispatch({
          TAG: "UpdateStatus",
          _0: "Saving"
        });
    $$Promise.wait(Api.saveProjectConcierges(projectId, state.selectedConcierges), (function (x) {
            if (x.TAG === "Ok") {
              return dispatch({
                          TAG: "UpdateStatus",
                          _0: "Ready"
                        });
            } else {
              return SentryLogger.error1({
                          rootModule: "ProjectActionButtonsForUser",
                          subModulePath: /* [] */0,
                          value: "make",
                          fullPath: "ProjectActionButtonsForUser.make"
                        }, "ProjectStickyBar::SaveConcierges::Error", [
                          "Error",
                          x._0
                        ]);
            }
          }));
  };
  var match$1 = React.useState(function () {
        return status;
      });
  var setStatus = match$1[1];
  var status$1 = match$1[0];
  var match$2 = React.useState(function () {
        return "Hidden";
      });
  var setDeleteModalVisibility = match$2[1];
  var updateProjectStatus = function (status) {
    $$Promise.wait(Api.updateProjectStatus(projectId, status), (function (x) {
            if (x.TAG !== "Ok") {
              return SentryLogger.error1({
                          rootModule: "ProjectActionButtonsForUser",
                          subModulePath: /* [] */0,
                          value: "make",
                          fullPath: "ProjectActionButtonsForUser.make"
                        }, "UpdateStatus::Error", [
                          "Error",
                          x._0
                        ]);
            }
            var match = x._0.project;
            if (match.TAG === "Draft") {
              return ;
            }
            var status = match.data.status;
            setStatus(function (param) {
                  return status;
                });
          }));
  };
  var tmp;
  if (!mobile && userRole !== "Provider") {
    var tmp$1;
    var exit = 0;
    switch (status$1) {
      case "Active" :
      case "Inactive" :
          exit = 1;
          break;
      default:
        tmp$1 = null;
    }
    if (exit === 1) {
      var tmp$2;
      tmp$2 = status$1 === "Active" ? true : false;
      tmp$1 = JsxRuntime.jsx(Toggle.make, {
            on: tmp$2,
            size: "SM",
            onChange: (function () {
                var tmp;
                switch (status$1) {
                  case "Draft" :
                      tmp = "Draft";
                      break;
                  case "Active" :
                      tmp = "Inactive";
                      break;
                  case "Inactive" :
                      tmp = "Active";
                      break;
                  case "Finalized" :
                      tmp = "Finalized";
                      break;
                  case "Review" :
                      tmp = "Review";
                      break;
                  
                }
                updateProjectStatus(tmp);
              })
          });
    }
    tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsxs("div", {
                  children: [
                    JsxRuntime.jsx("p", {
                          children: Project.Status.toString(status$1),
                          className: Cx.cx([
                                css.inactiveText,
                                statusClass(status$1)
                              ])
                        }),
                    tmp$1
                  ],
                  className: Cx.cx([
                        css.toggleContainer,
                        statusContainerClass(status$1)
                      ])
                }),
            JsxRuntime.jsx(Button.make, {
                  size: "XS",
                  color: "Teal",
                  buttonClassName: css.headerButton,
                  onClick: (function (param) {
                      Url.visit(Routes_Project.update(projectId));
                    }),
                  children: "Edit Project"
                })
          ]
        });
  } else {
    tmp = null;
  }
  var tmp$3;
  if (mobile || props.tablet) {
    var tmp$4;
    if (userRole !== "Provider" && props.viewContext === "Admin") {
      tmp$4 = JsxRuntime.jsx(ProjectStickyBarUsersDropdown.make, {
            title: "Concierge",
            users: projectStickyBarData.concierges,
            selectedUserIds: state.selectedConcierges,
            updateSelectedUsers: (function (selectedUserIds) {
                dispatch({
                      TAG: "UpdateConcierges",
                      _0: selectedUserIds
                    });
              }),
            usersListStatus: state.usersListStatus,
            save: saveConcierges
          });
    } else {
      var proposal = state.proposal;
      tmp$4 = proposal !== undefined ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(ProjectActionButtonsForUser$ProjectStickyBarProviderUsersDropdown, {
                      userProjectRole: state.userProjectRole,
                      users: projectStickyBarData.providerMembers,
                      selectedUserIds: state.selectedManagers,
                      updateSelectedUsers: (function (selectedUserIds) {
                          dispatch({
                                TAG: "UpdateManagers",
                                _0: selectedUserIds
                              });
                        }),
                      usersListStatus: state.usersListStatus,
                      save: props.saveManagers
                    }),
                JsxRuntime.jsx(ProjectProposalStatusDropdown.make, {
                      proposal: proposal,
                      projectId: projectId,
                      userViewContext: "Admin",
                      iconSize: "XS",
                      updateProposalStatus: props.updateProposalStatus,
                      setBidModalVisibility: (function (prim) {
                          
                        }),
                      className: css.providerDropdown,
                      position: {
                        TAG: "Above",
                        _0: "LeftEdge"
                      }
                    })
              ]
            }) : null;
    }
    var tmp$5;
    if (mobile && userRole !== "Provider") {
      var tmp$6;
      var exit$1 = 0;
      switch (status$1) {
        case "Active" :
        case "Inactive" :
            exit$1 = 1;
            break;
        default:
          tmp$6 = null;
      }
      if (exit$1 === 1) {
        var tmp$7;
        tmp$7 = status$1 === "Active" ? true : false;
        tmp$6 = JsxRuntime.jsx(Toggle.make, {
              on: tmp$7,
              size: "SM",
              onChange: (function () {
                  var tmp;
                  switch (status$1) {
                    case "Draft" :
                        tmp = "Draft";
                        break;
                    case "Active" :
                        tmp = "Inactive";
                        break;
                    case "Inactive" :
                        tmp = "Active";
                        break;
                    case "Finalized" :
                        tmp = "Finalized";
                        break;
                    case "Review" :
                        tmp = "Review";
                        break;
                    
                  }
                  updateProjectStatus(tmp);
                })
            });
      }
      tmp$5 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
            children: [
              JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx("p", {
                            children: Project.Status.toString(status$1),
                            className: Cx.cx([
                                  css.inactiveText,
                                  statusClass(status$1)
                                ])
                          }),
                      tmp$6
                    ],
                    className: css.statusPopoverRow
                  }),
              JsxRuntime.jsx("div", {
                    children: JsxRuntime.jsx("div", {
                          children: JsxRuntime.jsx("p", {
                                children: "Edit project",
                                className: css.popOverMenuText
                              }),
                          onClick: (function (param) {
                              Url.visit(Routes_Project.update(projectId));
                            })
                        }),
                    className: css.popoverRow
                  })
            ]
          });
    } else {
      tmp$5 = null;
    }
    tmp$3 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx("div", {
                  children: tmp$4,
                  className: css.popoverRow
                }),
            tmp$5
          ]
        });
  } else {
    tmp$3 = null;
  }
  var tmp$8;
  var exit$2 = 0;
  switch (userRole) {
    case "Admin" :
        var tmp$9;
        tmp$9 = status$1 === "Review" ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                children: [
                  JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx("p", {
                                    children: "Activate Project",
                                    className: css.popOverMenuText
                                  })
                            }),
                        className: css.popoverRow,
                        onClick: (function (param) {
                            updateProjectStatus("Active");
                          })
                      }),
                  JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx("p", {
                                    children: "Deactivate Project",
                                    className: css.popOverMenuText
                                  })
                            }),
                        className: css.popoverRow,
                        onClick: (function (param) {
                            updateProjectStatus("Inactive");
                          })
                      })
                ]
              }) : null;
        tmp$8 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx("p", {
                                  children: "Delete project",
                                  className: css.popOverMenuText
                                }),
                            onClick: (function (param) {
                                setDeleteModalVisibility(function (param) {
                                      return "Shown";
                                    });
                              })
                          }),
                      className: css.popoverRow
                    }),
                tmp$9
              ]
            });
        break;
    case "Concierge" :
    case "Agent" :
        exit$2 = 1;
        break;
    default:
      tmp$8 = null;
  }
  if (exit$2 === 1) {
    tmp$8 = status$1 === "Review" ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
            children: [
              JsxRuntime.jsx("div", {
                    children: JsxRuntime.jsx("div", {
                          children: JsxRuntime.jsx("p", {
                                children: "Activate Project",
                                className: css.popOverMenuText
                              })
                        }),
                    className: css.popoverRow,
                    onClick: (function (param) {
                        updateProjectStatus("Active");
                      })
                  }),
              JsxRuntime.jsx("div", {
                    children: JsxRuntime.jsx("div", {
                          children: JsxRuntime.jsx("p", {
                                children: "Deactivate Project",
                                className: css.popOverMenuText
                              })
                        }),
                    className: css.popoverRow,
                    onClick: (function (param) {
                        updateProjectStatus("Inactive");
                      })
                  })
            ]
          }) : null;
  }
  return JsxRuntime.jsxs("div", {
              children: [
                tmp,
                JsxRuntime.jsxs(Popover.make, {
                      className: css.popoverWrapper,
                      children: [
                        JsxRuntime.jsx(Popover.Trigger.make, {
                              className: css.popoverTrigger,
                              children: JsxRuntime.jsx(Button.make, {
                                    size: "XS",
                                    color: "Teal",
                                    buttonClassName: css.popoverButton,
                                    children: JsxRuntime.jsx(IconMenu.make, {
                                          size: "LG",
                                          color: "White",
                                          className: css.icon
                                        })
                                  })
                            }),
                        JsxRuntime.jsx(Popover.Body.make, {
                              position: {
                                TAG: "Above",
                                _0: "RightEdge"
                              },
                              className: css.popoverBody,
                              children: JsxRuntime.jsxs("div", {
                                    children: [
                                      tmp$3,
                                      userRole !== "Provider" ? JsxRuntime.jsx("div", {
                                              children: JsxRuntime.jsx("div", {
                                                    children: JsxRuntime.jsx("p", {
                                                          children: "Clone project",
                                                          className: css.popOverMenuText
                                                        }),
                                                    onClick: (function (param) {
                                                        ProjectService.cloneProject(projectId, category);
                                                      })
                                                  }),
                                              className: css.popoverRow
                                            }) : null,
                                      tmp$8
                                    ],
                                    className: css.popOverBodyWrapper
                                  })
                            }),
                        match$2[0] === "Shown" ? JsxRuntime.jsx("div", {
                                children: JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: "Are you sure?",
                                              className: css.modalMessageText
                                            }),
                                        JsxRuntime.jsxs("div", {
                                              children: [
                                                JsxRuntime.jsx(Button.make, {
                                                      size: "SM",
                                                      color: "Gray",
                                                      onClick: (function (param) {
                                                          setDeleteModalVisibility(function (param) {
                                                                return "Hidden";
                                                              });
                                                        }),
                                                      children: "Cancel"
                                                    }),
                                                JsxRuntime.jsx(Button.make, {
                                                      size: "SM",
                                                      color: "Pink",
                                                      onClick: (function (param) {
                                                          $$Promise.wait(Api.deleteProject(projectId), (function (x) {
                                                                  if (x.TAG === "Ok") {
                                                                    return Url.visit(Routes_Project.index);
                                                                  }
                                                                  SentryLogger.error({
                                                                        rootModule: "ProjectActionButtonsForUser",
                                                                        subModulePath: /* [] */0,
                                                                        value: "make",
                                                                        fullPath: "ProjectActionButtonsForUser.make"
                                                                      }, "DeleteProject::Error");
                                                                  Url.visit(Routes_Project.index);
                                                                }));
                                                        }),
                                                      children: "Yes, delete it"
                                                    })
                                              ],
                                              className: css.modalMessageButtons
                                            })
                                      ],
                                      className: css.modalMessageContainer
                                    }),
                                className: css.modalMessageOverlay
                              }) : null
                      ]
                    })
              ],
              className: css.actionButtonsContainer
            });
}

var make = ProjectActionButtonsForUser;

export {
  css ,
  Helpers ,
  reducer ,
  ProjectStickyBarProviderUsersDropdown ,
  make ,
}
/* css Not a pure module */
