// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../../../libs/ID.res.js";
import * as Role from "../../../../../models/Role.res.js";
import * as Project from "../../../../../models/Project.res.js";
import * as Provider from "../../../../../models/Provider.res.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as ProjectProposal from "../../../../../models/ProjectProposal.res.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", Project.Id.decoder),
              owner: field.required("owner", Project.Owner.decoder),
              status: field.required("status", Project.Status.decoder),
              category: field.required("category", Json_Decode$JsonCombinators.string),
              subcategory: field.required("subcategory", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

var StickyBarProject = {
  decoder: decoder,
  fromJson: fromJson
};

var decoder$1 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              firstName: field.required("firstName", Json_Decode$JsonCombinators.string),
              lastName: field.required("lastName", Json_Decode$JsonCombinators.string),
              role: field.required("role", Role.decoder),
              email: field.required("email", Json_Decode$JsonCombinators.string),
              phoneNumber: field.required("phone", Json_Decode$JsonCombinators.string),
              jobTitle: field.required("jobTitle", Json_Decode$JsonCombinators.string),
              profilePic: field.required("profilePic", Json_Decode$JsonCombinators.string)
            };
    });

function fromJson$1(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$1));
}

var StickyBarUser = {
  decoder: decoder$1,
  fromJson: fromJson$1
};

var decoder$2 = Json_Decode$JsonCombinators.object(function (field) {
      var currentProvider = field.required("currentProvider", Json_Decode$JsonCombinators.option(Provider.Simple.decoder));
      return {
              project: field.required("project", decoder),
              proposalDetails: field.required("proposalDetails", Json_Decode$JsonCombinators.option(ProjectProposal.decoder)),
              providersAsOwner: field.required("providersAsOwner", Json_Decode$JsonCombinators.array(Provider.Simple.decoder)),
              providersAsManager: field.required("providersAsManager", Json_Decode$JsonCombinators.array(Provider.Simple.decoder)),
              concierges: field.required("concierges", Json_Decode$JsonCombinators.array(decoder$1)),
              providerMembers: field.required("providerMembers", Json_Decode$JsonCombinators.array(decoder$1)),
              selectedConcierges: field.required("selectedConcierges", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.array(ID.decoder))),
              selectedManagers: field.required("selectedManagers", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.array(ID.decoder))),
              userProjectRole: Project.Role.fromString(field.required("userProjectRole", Json_Decode$JsonCombinators.option(Json_Decode$JsonCombinators.string)), currentProvider),
              currentProvider: currentProvider,
              isAdmin: field.required("isAdmin", Json_Decode$JsonCombinators.bool),
              isConcierge: field.required("isConcierge", Json_Decode$JsonCombinators.bool),
              isOwner: field.required("isOwner", Json_Decode$JsonCombinators.bool),
              isProviderOwner: field.required("isProviderOwner", Json_Decode$JsonCombinators.bool),
              isProviderManager: field.required("isProviderManager", Json_Decode$JsonCombinators.bool),
              isNotOnlyProviderMember: field.required("isNotOnlyProviderMember", Json_Decode$JsonCombinators.bool)
            };
    });

function fromJson$2(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$2));
}

export {
  StickyBarProject ,
  StickyBarUser ,
  decoder$2 as decoder,
  fromJson$2 as fromJson,
}
/* decoder Not a pure module */
